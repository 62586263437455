import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Triggering With A QuickBooks Custom Field">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`QuickBooks Online users have the option to configure a custom field on sales forms and use that to trigger review requests to customers.`}</p>
    <p>{`This approach gives a similar level of control as triggering requests directly from the Simple RM dashboard, but has the benefit of being done entirely inside QuickBooks Online.`}</p>
    <p>{`If you are unsure which is the best approach for triggering requests for your company, `}<a href='/contact/'>{`contact`}</a>{` our support team. We would be happy to provide a recommendation.`}</p>
    <h2 {...{
      "id": "triggering-a-request"
    }}>{`Triggering a Request`}</h2>
    <p>{`Once the custom field has been setup the workflow is simple. When you are creating the final invoice or sales receipt for a customer fill the Review Request custom field with the word “yes”.`}</p>
    <ul>
      <li parentName="ul">{`Sales receipts - a review request will be triggered right away.`}</li>
      <li parentName="ul">{`Invoices - the invoice will trigger a review request once it is paid by the customer`}</li>
    </ul>
    <p>{`Remember, only enter “yes” into the Request Review custom field if it is the payment that concludes your business with the customer, not a downpayment or deposit. Any value besides “yes”, including leaving the field empty, result in nothing happening.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
Note the Auto Send setting does not need to be active to trigger requests with the custom field. In fact, it is recommended you leave the setting off if you will be triggering requests using the custom field. That way a request is only sent when you choose.
    </Message>
    <h2 {...{
      "id": "adding-the-custom-field"
    }}>{`Adding the Custom Field`}</h2>
    <p>{`For those that don’t know, a custom feild is a value that lets you track additional pieces of information in QuickBooks Online.`}</p>
    <p>{`Setting up a custom field is done from inside your QuickBooks Online account. You can follow `}<a target="_blank" href='https://quickbooks.intuit.com/learn-support/en-us/help-article/customize-forms/add-custom-fields-sales-forms-purchase-orders/L6saJYHdg_US_en_US'>{`these instructions`}</a>{`.`}</p>
    <p>{`The custom field `}<strong parentName="p">{`must`}</strong>{` be named “Request Review” and make sure you choose to not display the custom field to customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      